

.App {
  width: 100vw !important;
}

.burger {
  position: absolute;
  top: 20px;
  right: 30px;
  z-index: 100;
}

.main-section {
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 100vw;
  font-size: calc(10px + 3vmin);
  overflow: hidden;
}

.section {
  margin: 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
  min-height: 50vh;
} 

h2 {
  text-align: center; 
  margin-bottom: 5px; 
  margin-top: 5px;
}

.countdown {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

a {
  color: #61dafb;
  text-decoration: none;
}
